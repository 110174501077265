import React from 'react'

const NotFound = () => {
  return (
    <div>
        This page does not exist... yet.
    </div>
  )
}

export default NotFound